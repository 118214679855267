import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./components/dashboard/pages/Dashboard";
// import Campaign from "./components/campaigns/pages/Campaign";
import Auth from "./components/auth/Auth";
import PlayerDetails from "./components/players/PlayerDetails";
import { AuthContext } from "./components/shared/context/auth-context";
import { useAuth } from "./components/shared/hooks/auth-hook";
import Settings from "./components/Settings/Pages/Settings";
import VerifyEmail from "./components/auth/VerifyEmail";
import Verified from "./components/auth/Verified";
import ResetPassword from "./components/auth/ResetPassword";
import NewPassword from "./components/auth/NewPassword";
import WhatsappSettings from "./components/whatsapp/WhatsappSettings";
import CreateWhatsAppBusiness from "./components/whatsapp/CreateWhatsAppBusiness";

function App() {
  const { token, login, logout, userId, isLoading } = useAuth();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  let routes;

  useEffect(() => {
    if (!isLoading) {
      setIsCheckingAuth(false);
    }
  }, [isLoading]);

  if (isLoading || isCheckingAuth) {
    routes = <div>Loading...</div>;
  } else if (token) {
    routes = (
      <>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/player/details/:email">
            <PlayerDetails />
          </Route>
          <Route exact path="/whatsapp-settings">
            <WhatsappSettings />
          </Route>
          <Route exact path="/create-whatsapp-business">
            <CreateWhatsAppBusiness />
          </Route>
          <Redirect to="/" />
        </Switch>
      </>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/auth">
          <Auth />
        </Route>
        <Route exact path="/verify-email">
          <VerifyEmail />
        </Route>
        <Route exact path="/verified">
          <Verified />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/new-password/:token">
          <NewPassword />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <Router>{routes}</Router>
    </AuthContext.Provider>
  );
}

export default App;
