import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import MainNavigation from "../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";
import axios from "axios";
import {
  Layout,
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Button,
  Select,
  Alert,
  Drawer,
} from "antd";
const { Content, Sider } = Layout;

function PlayerDetails() {
  const auth = useContext(AuthContext);
  const [playerDetail, setPlayerDetail] = useState();
  const { email } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}player-register-detail`,
          {
            params: { email: email },
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        const userData = response.data;

        console.log(userData.product); // Check the value

        // Set the retrieved data in the formData state
        setPlayerDetail(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [email, auth.token]);

  console.log(playerDetail, "player");
  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <h1>{playerDetail?.name}</h1>
            <h1>{playerDetail?.phone}</h1>
            <p>{playerDetail?.address}</p>
            <p>{playerDetail?.city}</p>
            <p>{playerDetail?.dateOfBirth}</p>
            <p>{playerDetail?.district}</p>
            <p>{playerDetail?.pincode}</p>
            <p>{playerDetail?.state}</p>
            <p>{playerDetail?.email}</p>
            <p>{playerDetail?.gender}</p>
            <p>{playerDetail?.guardianName}</p>
            <p>{playerDetail?.guardianRelation}</p>
            <p>{playerDetail?.montherToungue}</p>
            <p>{playerDetail?.type}</p>
            <img
              src={`https://billsubmit.sgp1.digitaloceanspaces.com/DCA/photos/${playerDetail?.photo}`}
              alt="photoo"
              style={{ width: "200px" }}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default PlayerDetails;
