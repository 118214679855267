import React, { useState, useContext } from "react";
import "./Auth.css";
import { Link, useHistory } from "react-router-dom";
import { Input, Form, Button, Spin } from "antd";
import { useForm } from "../shared/hooks/form-hook";
import ErrorModal from "../shared/uiElement/ErrorModal";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import thumb from "../../img/Chesys-logos_black.png";
import loginPic from "../../img/Login.png";

const Auth = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [org, setOrg] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const authSubmitHandler = async (event) => {
    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "auth/login",
          "POST",
          JSON.stringify({
            email: email,
            password: password,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        console.log(responseData);
        if (responseData.isVerified === true) {
          auth.login(responseData.userId, responseData.token);
        } else if (responseData.isVerified === false) {
          window.location.href = "/verify-email";
        }

        // auth.login(responseData.userId, responseData.token);
        console.log(responseData.name);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "auth/signup",
          "POST",
          JSON.stringify({
            name: name,
            email: email,
            password: password,
            organization: org,
            phone: phone,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        console.log(responseData);
        if (responseData.isVerified === false) {
          window.location.href = "/verify-email";
        } else if (responseData.isVerified === true) {
          auth.login(responseData.userId, responseData.token);
          console.log("name", responseData.name);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };
  return (
    <div className="auth-comp">
      <div className="auth-header">
        <div>
          <img className="auth-logo" src={thumb} alt="logo" />
        </div>
        <Form
          className="auth-form"
          layout="vertical"
          onFinish={authSubmitHandler}
        >
          {!isLoginMode && (
            <>
              <Form.Item label="Enter Your Full Name">
                <Input
                  id="name"
                  placeholder="John Doe"
                  type="text"
                  size="large"
                  onChange={(e) => setName(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Please input your full name!",
                    },
                  ]}
                />
              </Form.Item>

              <div className="form-row">
                <Form.Item label="Enter Your Number" style={{ width: "100%" }}>
                  <Input
                    id="phone"
                    placeholder="+91 9876543210"
                    type="phone"
                    size="large"
                    onChange={(e) => setPhone(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone!",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item label="Organization Name" style={{ width: "100%" }}>
                  <Input
                    id="organization"
                    placeholder="Enter your org name"
                    type="organization"
                    size="large"
                    onChange={(e) => setOrg(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </>
          )}
          <Form.Item label="Enter Email">
            <Input
              id="email"
              placeholder="johndoe@gmail.com"
              type="email"
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label="Enter Your Password">
            <Input
              id="password"
              type="password"
              size="large"
              placeholder="Enter Your Password"
              onChange={(e) => setPassword(e.target.value)}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            />
          </Form.Item>
          <Button
            className="auth-button"
            size="large"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {isLoginMode ? "Login Now" : "Register Now"}
          </Button>
          {/* {isLoading && <Spin />} */}
        </Form>
        <ErrorModal error={error} />
        {/* <div className="auth-option">
          <p className="auth-register" onClick={switchModeHandler}>
            {isLoginMode
              ? "Don't Have an Account? Register Now"
              : "Have an account? Login Now"}
          </p>
          {isLoginMode && (
            <Link to="/reset-password">
              <p className="auth-forgot">
                {isLoginMode ? "Forgot Password?" : null}
              </p>
            </Link>
          )}
        </div> */}
      </div>
      <div className="auth-vector">
        <img src={loginPic} alt="login" />
      </div>
    </div>
  );
};

export default Auth;
