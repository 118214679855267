import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "./Auth.css";
import { Input, Form, Button, Spin } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import thumb from "../../img/logo-bill-color.png";
import loginPic from "../../img/password.png";

function NewPassword() {
  const auth = useContext(AuthContext);
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const { token } = useParams();
  console.log(token);
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}auth/new-password/${token}`,
        "POST",
        JSON.stringify({
          password: password,
          token: token,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      const data = await response.message;

      if (!response.ok) {
        throw new Error(data);
      }
      setMessage(data);
      window.location.href = "/auth";
    } catch (err) {
      setMessage(err.message);
    }
  };

  return (
    <div className="auth-comp">
      <div className="auth-header">
        <div>
          <Link to="/">
            <img
              style={{ paddingLeft: "5%" }}
              className="auth-logo"
              src={thumb}
              alt="logo"
            />
          </Link>
        </div>
        {/* {isLoading && <Spin />} */}
        <div style={{ width: "80%", padding: "0% 5% 0% 5%" }}>
          <h2 style={{ margin: 0 }}>Set New Password</h2>
          <p style={{ marginTop: 10 }}>Please enter your new password.</p>
          <Form
            style={{ width: "80%" }}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item label="Enter your new password">
              <Input
                id="password"
                placeholder="your new password"
                type="password"
                size="large"
                onChange={(e) => setPassword(e.target.value)}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              />
            </Form.Item>
            <Button
              style={{ width: "50%" }}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Reset Password
            </Button>
          </Form>

          {message && (
            <p>
              {message} <Link to="/auth">Login Now</Link>{" "}
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          width: "50%",
          backgroundColor: "#ECF3FA",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          style={{ width: "70%", padding: "20px", margin: "auto" }}
          src={loginPic}
          alt="login"
        />
      </div>
    </div>
  );
}

export default NewPassword;
