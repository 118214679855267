import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";

import moment from "moment";

import {
  Layout,
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Button,
  Select,
  Alert,
  Drawer,
} from "antd";
const { Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;

function Dashboard() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedBills, setLoadedBills] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentBill, setCurrentBill] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [profile, setProfile] = useState();
  const [companies, setCompanies] = useState([]);

  // console.log(auth.token);

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
  //         {
  //           headers: { Authorization: `Bearer ${auth.token}` },
  //         }
  //       );
  //       setLoading(false);
  //       setProfile(response.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchProfile();
  // }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchTournament = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}players`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const tournaments = response.data.map((row) => ({
          id: row._id,
          name: row.name,
          status: row.status,
          email: row.email,
          phone: row.phone,
          product: row.product,
          createdAt: moment(row.createdAt).format("YYYY-MM-DD"),
        }));

        // Sort the bills by date in descending order
        tournaments.sort((a, b) => new Date(b.date) - new Date(a.date));

        setTournaments(tournaments);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTournament();
  }, [auth.userId, auth.token]);

  const columns = [
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link
          to={`/player/details/${record.email}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("DD MMM YYYY")}</span>,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Link
    //         to={`/tournaments/details/${record.id}`}
    //         style={{ textDecoration: "none" }}
    //       >
    //         See Details
    //       </Link>
    //       {/* <p
    //         style={{ color: "red", margin: 0, cursor: "pointer" }}
    //         onClick={() => deleteHandler(record.id)}
    //       >
    //         Delete
    //       </p> */}
    //     </Space>
    //   ),
    // },
  ];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div style={{ flex: 1, marginRight: "20px" }}>
                  <div className="main-heading">
                    <div>
                      <h2>All Players</h2>
                    </div>
                  </div>

                  <Table
                    style={{ marginTop: 30 }}
                    dataSource={tournaments}
                    columns={columns}
                    loading={loading}
                    rowKey={(record) => record.id}
                    size="middle"
                    pagination={{ pageSize: 25 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
