import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import MainNavigation from "../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  Layout,
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Button,
  Select,
  Alert,
  Tabs,
  Row,
  Col,
  Avatar,
  Divider,
} from "antd";
const { Content, Sider } = Layout;
const { TabPane } = Tabs;

function WhatsappSettings() {
  const auth = useContext(AuthContext);

  const [businessProfiles, setBusinessProfiles] = useState([]);
  const [project, setProject] = useState();
  const [loading, setLoading] = useState();
  const [embeddedSignupUrl, setEmbeddedSignupUrl] = useState(null);

  //   const { email } = useParams();

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}whatsapp/settings/businesses/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        const userData = response.data;

        console.log(userData); // Check the value

        // Set the retrieved data in the formData state
        setLoading(false);

        setBusinessProfiles(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [auth.token, auth.userId]);

  const businessId = businessProfiles[0]?.businessId;
  console.log(businessId, "busine");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}whatsapp/settings/project/${businessId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        const userData = response?.data;

        console.log(userData, "projext"); // Check the value

        // Set the retrieved data in the formData state
        setProject(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [auth.token, businessId]);

  const fetchEmbeddedSignupUrl = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}whatsapp/settings/generate-waba-link`,
        {
          businessId: businessProfiles[0]?.businessId,
          projectId: project.id,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setEmbeddedSignupUrl(response.data.embeddedSignupUrl);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (businessProfiles.length > 0) {
      fetchEmbeddedSignupUrl();
    }
  }, [businessProfiles, fetchEmbeddedSignupUrl]);

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1>WhatsApp Business Profile</h1>
              {businessProfiles.length === 0 && (
                <Link to="/create-whatsapp-business">
                  <Button
                    size="large"
                    type="primary"
                    style={{ backgroundColor: "#000", border: "none" }}
                  >
                    Create Business profile
                  </Button>
                </Link>
              )}
            </div>

            {businessProfiles.length === 0 && (
              <Alert
                message="Before starting integration!"
                type="info"
                style={{ marginBottom: "30px", marginTop: "30px" }}
                description={
                  <>
                    <li>
                      Make sure you have a new or unused number for your
                      organization’s WhatsApp Business
                    </li>
                    <li>
                      Keep your business phone number handy for OTP
                      verification.
                    </li>
                    <li>Keep your Facebook Business Manager account ready.</li>
                  </>
                }
              />
            )}

            <div>
              {businessProfiles.length > 0 ? (
                <Row align="middle">
                  <Col span={24} style={{ marginTop: "20px" }}>
                    <Col span={2}>
                      <Avatar size={64} icon={<UserOutlined />} />
                    </Col>
                    <Col style={{ marginTop: "20px" }}>
                      <h2 style={{ margin: 0 }}>
                        {businessProfiles[0]?.companyName}
                      </h2>
                      <Row style={{ marginTop: "10px" }}>
                        <Col span={6}>
                          <p style={{ margin: 0 }}>Contact Number</p>
                          <h4 style={{ margin: 0, marginTop: 6 }}>
                            {businessProfiles[0]?.contactNumber}
                          </h4>
                        </Col>
                        <Col span={8}>
                          <p style={{ margin: 0 }}>Admin Email</p>
                          <h4 style={{ margin: 0, marginTop: 6 }}>
                            {businessProfiles[0]?.email}
                          </h4>
                        </Col>
                        <Col span={8}>
                          <p style={{ margin: 0 }}>Display Name</p>
                          <h4 style={{ margin: 0, marginTop: 6 }}>
                            {businessProfiles[0]?.displayName}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              ) : (
                <p>
                  No business profile found. Please create one to start WhatsApp
                  integration.
                </p>
              )}
            </div>

            {/* <Divider style={{ marginTop: "50px" }} /> */}

            <div>
              <Row
                align="middle"
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "20px",
                  marginTop: "40px",
                }}
              >
                <Col span={8}>
                  <p style={{ margin: 0 }}>Project Name</p>
                  <h4 style={{ margin: 0, marginTop: "4px" }}>
                    {project?.name}
                  </h4>
                </Col>
                <Col span={8}>
                  <p style={{ margin: 0 }}>WhatsApp Linked Number</p>
                  <h4 style={{ margin: 0, marginTop: "4px" }}>
                    E.g {project?.waNumber}
                  </h4>
                </Col>
                <Col span={8}>
                  <p style={{ margin: 0 }}>Status</p>
                  <Tag
                    color={project?.status === "active" ? "green" : "red"}
                    style={{ marginTop: "4px" }}
                  >
                    {project?.status}
                  </Tag>
                </Col>
              </Row>
            </div>

            <Row style={{ marginTop: "40px" }}>
              <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
                <TabPane tab="Setup" key="1">
                  <Row align="middle">
                    <Col span={20}>
                      <h1>Connect With Facebook</h1>
                    </Col>
                    <Col span={4}>
                      {embeddedSignupUrl && (
                        <a
                          href={embeddedSignupUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            size="large"
                            type="primary"
                            style={{
                              backgroundColor: "#000",
                              border: "none",
                            }}
                          >
                            Connect With Facebook
                          </Button>
                        </a>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Details" key="2">
                  <Col span={24}>
                    <h1>WhatsApp Channel</h1>
                  </Col>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={8}>Phone Number:</Col>
                    <Col span={16}>{project?.waNumber}</Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={8}>Display Name:</Col>
                    <Col span={16}>{project?.waDisplayName}</Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={8}>Quality Rating:</Col>
                    <Col span={16}>{project?.waQualityRating}</Col>
                  </Row>
                </TabPane>
                <TabPane tab="Analytics" key="3">
                  <Col span={24}>
                    <h1>Your Analytics</h1>
                  </Col>
                </TabPane>
                <TabPane tab="Templates" key="4">
                  Content of Tab Pane 1
                </TabPane>
                <TabPane tab="Profile" key="5">
                  Content of Tab Pane 1
                </TabPane>
              </Tabs>
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default WhatsappSettings;
