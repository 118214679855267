import React from "react";

import Button from "../FormElements/Button";

const ErrorModal = (props) => {
  console.log(props.error);
  return (
    <div
      onCancel={props.onClear}
      header="An Error Occurred!"
      show={!!props.error}
      footer={<Button onClick={props.onClear}>Okay</Button>}
    >
      <p style={{ color: "red" }}>{props.error}</p>
    </div>
  );
};

export default ErrorModal;
