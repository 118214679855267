import React, { useState, useContext } from "react";
import "./Auth.css";
import { Input, Form, Button, Spin } from "antd";
import { Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import thumb from "../../img/logo-bill-color.png";
import loginPic from "../../img/verify.png";

const VerifyEmail = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  return (
    <div className="auth-comp">
      <div className="auth-header">
        <div>
          <Link to="/">
            <img
              style={{ paddingLeft: "2%" }}
              className="auth-logo"
              src={thumb}
              alt="logo"
            />
          </Link>
        </div>
        {isLoading && <Spin />}
        <div style={{ width: "80%", padding: "0% 5% 0% 5%" }}>
          <h2>Verify Your Email</h2>
          <p>Please confirm your email, we just sent you an email to verify.</p>
        </div>
      </div>
      <div
        style={{
          width: "50%",
          backgroundColor: "#ECF3FA",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          style={{ width: "70%", padding: "20px", margin: "auto" }}
          src={loginPic}
          alt="login"
        />
      </div>
    </div>
  );
};

export default VerifyEmail;
