import React, { useState, useContext } from "react";
import "./Auth.css";
import { Input, Form, Button, Alert, Spin } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import thumb from "../../img/logo-bill-color.png";
import loginPic from "../../img/password.png";

function ResetPassword() {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  const authSubmitHandler = async (event) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "auth/reset-password",
        "POST",
        JSON.stringify({
          email: email,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      console.log(responseData.message);
      const data = await responseData.message;

      if (!responseData.ok) {
        throw new Error(data);
      }

      setMessage(data);
      window.location.href = "/auth";
    } catch (err) {
      setMessage(err.message);
    }
  };

  return (
    <div className="auth-comp">
      <div className="auth-header">
        <div>
          <Link to="/">
            <img
              style={{ paddingLeft: "5%" }}
              className="auth-logo"
              src={thumb}
              alt="logo"
            />
          </Link>
        </div>
        {/* {isLoading && <Spin />} */}
        <div style={{ width: "80%", padding: "0% 5% 0% 5%" }}>
          <h2 style={{ margin: 0 }}>Forgot Password?</h2>
          <p style={{ marginTop: 10 }}>
            Please enter your email address to reset your password.
          </p>
          <Form
            style={{ width: "80%" }}
            layout="vertical"
            onFinish={authSubmitHandler}
          >
            <Form.Item label="Enter your email address">
              <Input
                id="email"
                placeholder="john@example.com"
                type="email"
                size="large"
                onChange={(e) => setEmail(e.target.value)}
                rules={[
                  {
                    required: true,
                    message: "Please input your email address!",
                  },
                ]}
              />
            </Form.Item>
            <Button
              style={{ width: "50%" }}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Reset Password
            </Button>
          </Form>
          <div style={{ marginTop: "20px" }}>
            {/* {message?.includes("Password reset email sent") ? (
              <Alert message={message} type="success" />
            ) : (
              <Alert message={message} type="error" />
            )} */}
          </div>
          {message && <p>{message} </p>}
        </div>
      </div>
      <div
        style={{
          width: "50%",
          backgroundColor: "#ECF3FA",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          style={{ width: "70%", padding: "20px", margin: "auto" }}
          src={loginPic}
          alt="login"
        />
      </div>
    </div>
  );
}

export default ResetPassword;
