import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import CompanyList from "../Components/CompanyList";
import ProfileSetting from "../Components/ProfileSetting";
import CategoryList from "../Components/CategoryList";

import {
  Layout,
  Space,
  Table,
  Tabs,
  Input,
  DatePicker,
  Button,
  Spin,
} from "antd";
const { Content, Sider } = Layout;
const { TabPane } = Tabs;

function Settings() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [companies, setCompanies] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [dates, setDates] = useState(null);
  const [filteredBills, setFilteredBills] = useState([]);

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | BillSubmit</title>
        <link rel="canonical" href="https://app.billsubmit.com/settings" />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="main-heading">
              <div>
                <h2>Settings</h2>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Link to="/create-category">
                  <Button type="primary" size="large">
                    Add New Category
                  </Button>
                </Link>
                <Link to="/create-company">
                  <Button type="primary" size="large">
                    Add New Company
                  </Button>
                </Link>
              </div>
            </div>
            <div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="All Companies" key="1">
                  <CompanyList />
                </TabPane>
                <TabPane tab="All Categories" key="2">
                  <CategoryList />
                </TabPane>
                <TabPane tab="Profile Details" key="3">
                  <ProfileSetting />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Settings;
