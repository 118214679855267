import React, { useState, useContext } from "react";
import "./Auth.css";
import { Input, Form, Button, Spin } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import thumb from "../../img/logo-bill-color.png";
import loginPic from "../../img/verified.png";

const Verified = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  return (
    <div className="auth-comp">
      <div className="auth-header">
        {/* <div>
          <Link to="/">
            <img
              style={{ paddingLeft: "2%" }}
              className="auth-logo"
              src={thumb}
              alt="logo"
            />
          </Link>
        </div> */}
        {isLoading && <Spin />}
        <div style={{ width: "80%", padding: "0% 5% 0% 5%" }}>
          <CheckCircleTwoTone
            style={{ fontSize: "2.8rem", marginBottom: "20px" }}
            twoToneColor="#52c41a"
          />

          <h2 style={{ margin: 0 }}>Email Verified</h2>
          <p style={{ marginTop: 10 }}>
            Your email has been successfully verified, You can now login to add
            your bills.
          </p>

          <Link to="/auth">
            <Button
              style={{ width: "50%" }}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Login Now
            </Button>
          </Link>
        </div>
      </div>
      <div
        style={{
          width: "50%",
          backgroundColor: "#ECF3FA",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          style={{ width: "70%", padding: "20px", margin: "auto" }}
          src={loginPic}
          alt="login"
        />
      </div>
    </div>
  );
};

export default Verified;
